<template>
  <div id="kpnav_app">
    <iframe
      id="kpnav_cookie-helper-iframe"
      src="https://tools.coursedev.keypathedu.com/cookie-helper/index.html"
    ></iframe>
    <button id="kpnav_hamburger" @click="toggleDrawer">
      <HamburgerIcon />
    </button>
    <Menu />
    <transition name="fade">
      <div id="kpnav_backdrop" v-if="drawerOpen" @click="closeDrawer" />
    </transition>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import HamburgerIcon from './components/icons/HamburgerIcon.vue';
import Menu from './components/Menu.vue';
import { event } from 'vue-gtag';

const store = useStore();

const drawerOpen = computed(() => store.state.drawerOpen);

function toggleDrawer() {
  drawerOpen ? event('toggle_close') : event('toggle_open');
  store.commit('toggleDrawer');
}
function closeDrawer() {
  event('toggle_close');
  store.commit('toggleDrawer');
}
</script>

<style lang="scss">
@import '@/variables.scss';

.kpnav_sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

#kpnav_app {
  height: 0px;
  width: 0px;
  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;

  a,
  p,
  ul,
  li,
  div,
  label {
    font-size: 16px;
    font-family: $font !important;
  }

  * {
    font-family: $font !important;
  }

  label {
    font-weight: 400; //needed for Keyosk conflict
    margin-bottom: 0; //needed for Keyosk conflict
  }

  a {
    line-height: 1; //needed for aston snippets conflict
  }

  span {
    margin-bottom: 0; //needed for aston snippets conflict
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-keypath;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-keypath;
  }
}

#kpnav_cookie-helper-iframe {
  width: 2px;
  height: 2px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
}

#kpnav_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s ease;
}

#kpnav_hamburger {
  position: fixed;
  bottom: 10px;
  left: 10px;

  background: white;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $primary-keypath;
  color: $primary-keypath;
  z-index: 2;

  &:hover,
  &:focus {
    color: white;
    background: $primary-keypath;
  }
}

// transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
