export const products = {
  gizmo: {
    displayName: 'Gizmo',
    homepageLink: 'https://gizmo.coursedev.keypathedu.com/index.html',
  },
  dolly: {
    displayName: 'Dolly',
  },
  keyosk: {
    displayName: 'Keyosk',
    homepageLink: 'https://keyosk.coursedev.keypathedu.com',
    partnerPageLink: 'https://keyosk.coursedev.keypathedu.com/interactives/school/',
  },
  lms: {
    displayName: 'LMS',
  },
  cqr: {
    displayName: 'CQR',
    homepageLink: 'https://cqr.coursedev.keypathedu.com/',
  },
  snippets: {
    displayName: 'Snippets',
  },
  phonehome: {
    displayName: 'Phone Home',
    partnerPageLink:
      'https://app.powerbi.com/links/6uGNUO9T2-?ctid=722b0db7-9629-4304-92a0-dfb4a1debe62&pbi_source=linkShare&filter=phonehome/partner%20eq%20%27',
  },
  uiKit: {
    displayName: 'UI Kit',
  },
  requestCreator: {
    displayName: 'Jira Request Creator',
    staticLink:
      'https://apps.powerapps.com/play/e/default-722b0db7-9629-4304-92a0-dfb4a1debe62/a/da808004-4a79-40d2-9b77-5848f9bf7fe2?tenantId=722b0db7-9629-4304-92a0-dfb4a1debe62&source=portal#',
  },
  bitbucket: {
    displayName: 'Bitbucket',
  },
  keyper: {
    displayName: 'Keyper',
    staticLink: 'https://keyper.coursedev.keypathedu.com',
  },
  fastFiles: {
    displayName: 'Fast Files',
  },
};

export const partners = {
  /********* IMPORTANT *********
  this is ordered by Acronym alphabetic.
  ********* IMPORTANT *********/
  aic: {
    displayName: 'American International College (AIC)',
    links: {
      dolly: 'https://courseapps.online.aic.edu/dolly/public/',
      lms: 'https://aic.instructure.com/',
      snippets: 'https://courseapps.online.aic.edu/snippets/',
      uiKit: 'https://www.figma.com/file/X0WK3IPf2KSPSpzBJoRMKR/AIC-UI-Kit-(AshP)?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/aic/src/main/',
      fastFiles: 'https://courseapps.online.aic.edu/fastfiles/dist/#/',
    },
  },
  aston: {
    displayName: 'Aston University (AU)',
    noPhoneHome: true,
    links: {
      dolly: 'https://courseapps.aston.ac.uk/dolly/public',
      lms: 'https://vle.aston.ac.uk',
      snippets: 'https://keypath-coursedev.s3.amazonaws.com/snippets/Aston/index.html',
      bitbucket: 'https://bitbucket.org/keypathcd/aston/src/main/',
    },
    offboarded: true,
  },

  baylor: {
    displayName: 'Baylor University (BU)',
    links: {
      dolly: 'https://courseapps.onlinenursing.baylor.edu/dev/dolly/public',
      lms: 'https://www.baylor.edu/canvas',
      snippets: 'https://courseapps.onlinenursing.baylor.edu/dev/theme/snippets/index.html',
      uiKit: 'https://www.figma.com/file/gqg2ujxN65HnpCBmZ4zqYC/Baylor-UI-Kit?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/baylor/src/main/',
      fastFiles: 'https://courseapps.onlinenursing.baylor.edu/dev/fastfiles/dist/#/',
    },
  },

  ccc: {
    displayName: 'Central Christian College (CCC)',
    links: {
      dolly: '',
      lms: 'https://centralchristiancollege.instructure.com',
      snippets: '',
    },
  },

  csu: {
    displayName: 'Cleveland State University (CSU)',
    links: {
      dolly: 'https://courseapps.onlinelearning.csuohio.edu/dolly/public/',
      lms: 'https://bb-csuohio.blackboard.com/',
      snippets: 'https://courseapps.onlinelearning.csuohio.edu/snippets/',
      bitbucket: 'https://bitbucket.org/keypathcd/csu/src/main/',
      uiKit:
        'https://www.figma.com/file/1WfY1fjNMl6ct9yDBuD4v9/CSU-UI-Kit?type=design&node-id=0%3A1&mode=design&t=Zyk4amM0eiCgwKLR-1',
      fastFiles: 'https://courseapps.onlinelearning.csuohio.edu/fastfiles/dist/#/',
    },
  },

  emerson: {
    displayName: 'Emerson (EC)',
    links: {
      dolly: 'https://courseapps.marketinganalytics.emerson.edu/dev/dolly/public',
      lms: 'https://canvas.emerson.edu',
      snippets: 'https://courseapps.marketinganalytics.emerson.edu/dev/theme/snippets',
      bitbucket: 'https://bitbucket.org/keypathcd/emerson/src/main/',
    },
    offboarded: true,
  },

  ecu: {
    displayName: 'Edith Cowan University (ECU)',
    links: {
      dolly: 'https://ao.ecu.edu.au/dev/dolly/public',
      lms: 'https://mylogin.ecu.edu.au/IAMSSO/login.jsp',
      snippets: 'https://ao.ecu.edu.au/dev/snippets/index.html',
      uiKit: 'https://xd.adobe.com/view/d87496a7-6255-473f-a9ee-7e64c3f04bed-8d27/',
      bitbucket: 'https://bitbucket.org/keypathcd/ecu-canvas/src/main/',
    },
  },

  elmhurst: {
    displayName: 'Elmhurst University (EU)',
    links: {
      dolly: 'https://courseapps.onlinedegrees.elmhurst.edu/dolly/public',
      lms: 'https://elmhurst.blackboard.com/webapps/login/?action=default_login',
      snippets: 'https://courseapps.onlinedegrees.elmhurst.edu/dev/theme/snippets',
      uiKit: 'https://www.figma.com/file/V3fgWbOy4HvzkBTsnxlKsX/Elmhurst-UI-Kit-(AshP)?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/elmhurst/src/main/',
      fastFiles: 'https://courseapps.onlinedegrees.elmhurst.edu/fastfiles/dist/#/',
    },
  },

  fuo: {
    displayName: 'Felician University Online (FUO)',
    links: {
      dolly: 'https://courseapps.online.felician.edu/dolly/public/',
      lms: 'https://felician.brightspace.com/d2l/login',
      snippets: 'https://courseapps.online.felician.edu/internal/snippets/index.html#/',
      uiKit:
        'https://www.figma.com/file/8DUkn57MA96nuhNBeuPolz/Felician-UI-Kit?type=design&node-id=0-1&mode=design&t=qFgs8HxUGqOyfsCx-0',
      bitbucket: 'https://bitbucket.org/keypathcd/fuo/src/main/',
    },
  },

  holyfamily: {
    displayName: 'Holy Family University (HFU)',
    links: {
      dolly: 'https://courseapps.onlinenursing.holyfamily.edu/dolly/public',
      lms: 'https://holyfamily.instructure.com/',
      snippets: 'https://courseapps.onlinenursing.holyfamily.edu/snippets/',
      bitbucket: 'https://bitbucket.org/keypathcd/holy-family/src/main/',
      uiKit:
        'https://www.figma.com/file/ldttN36XdB0YuDw6qjBo7L/Holy-Family-UI-Kit?type=design&node-id=46%3A4&mode=design&t=ZB8ke5Wh0ZzSwbtB-1',
      fastFiles: 'https://courseapps.onlinenursing.holyfamily.edu/fastfiles/dist/#/',
    },
  },

  ithaca: {
    displayName: 'Ithaca College (ICO)',
    links: {
      dolly: 'https://courseapps.slponline.ithaca.edu/dolly/public',
      lms: 'https://ithaca.instructure.com/',
      snippets: 'https://courseapps.slponline.ithaca.edu/internal/snippets/index.html#/',
      uiKit: 'https://www.figma.com/design/mlXQjSMRyahcO6RKOX5qF5/Ithaca-UI-Kit?node-id=538-1125&t=30TeLA7kbVpzSLxM-0',
      bitbucket: 'https://bitbucket.org/keypathcd/ithaca/src/main/',
    },
  },

  jcu: {
    displayName: 'James Cook University (JCU)',
    links: {
      dolly: 'https://courseapps.jcu.edu.au/dolly',
      lms: 'https://learn.jcu.edu.au/?system_login=true',
      snippets: 'https://courseapps.jcu.edu.au/courses/snippet/2024/index.html',
      uiKit: 'https://xd.adobe.com/view/88637bc3-58c4-4c5f-95f8-03e9d2d85fa1-dd30/',
      bitbucket: 'https://bitbucket.org/keypathcd/jcu/src/main/',
    },
  },

  keuka: {
    displayName: 'Keuka College (KC)',
    links: {
      dolly: 'https://courseapps.onlinedegrees.keuka.edu/dolly/public/',
      snippets: 'https://keypath-coursedev.s3.amazonaws.com/keuka/snippets/index.html',
      lms: 'https://learning.keuka.edu/?saml=off',
      uiKit: 'https://www.figma.com/file/76vWZoVIm4Pz6fjEQgaerP/Keuka-UI-Kit-(AshP)?node-id=46-4&t=Byy8vaU2yYM6FkTK-0',
      bitbucket: 'https://bitbucket.org/keypathcd/keuka/src/main/',
      fastFiles: 'https://courseapps.onlinedegrees.keuka.edu/fastfiles/dist/#/',
    },
  },

  keypath: {
    displayName: 'Keypath',
    links: {
      dolly: 'https://dev7.coursedev.keypathedu.com/dolly/public',
      lms: 'https://demo2.coursedev.keypathedu.com',
      snippets: '',
    },
  },

  kphealth: {
    displayName: 'Keypath Health',
    links: {
      dolly: 'https://dev7.coursedev.keypathedu.com/dolly/public',
      lms: 'http://demo2.coursedev.keypathedu.com/accounts/5',
      snippets: 'http://dev7.coursedev.keypathedu.com/demo2-nursing/theme/snippets',
      bitbucket: 'https://bitbucket.org/keypathcd/health-content/src/main/',
    },
  },

  marymount: {
    displayName: 'Marymount University (MUO)',
    links: {
      dolly: 'https://courseapps.online.marymount.edu/dev/dolly/public',
      lms: 'https://marymount.instructure.com/',
      snippets: 'https://courseapps.online.marymount.edu/snippets/',
      uiKit:
        'https://www.figma.com/file/jwMToWww5hndnqYHakUUJt/Marymount-UI-Kit?type=design&node-id=0%3A1&mode=design&t=cWgqmgvuL9RqaSEN-1',
      bitbucket: 'https://bitbucket.org/keypathcd/marymount/src/main/',
    },
  },

  mbs: {
    displayName: 'Melbourne Business School (MBSO)',
    links: {
      dolly: 'https://courseapps.online.mbs.edu/dolly/public/',
      lms: 'https://mbsedu.instructure.com/login/canvas',
      snippets: 'https://courseapps.online.mbs.edu/snippets/index.html',
      bitbucket: 'https://bitbucket.org/keypathcd/melbourne/src/main/',
      fastFiles: 'https://courseapps.online.mbs.edu/fastfiles/dist/#/',
    },
  },

  mtu: {
    displayName: 'Michigan Technological University (MTU)',
    links: {
      // dolly: 'https://keypath.courses.mtu.edu/apps/dolly/public',
      lms: 'https://mtu.instructure.com',
      snippets: 'https://www.dropbox.com/home/templates/MTU/Snippets%20%2B%20Templates/snipets',
      bitbucket: 'https://bitbucket.org/keypathcd/mtu/src/main/',
    },
  },

  pepperdine: {
    displayName: 'Pepperdine (PUO)',
    links: {
      dolly: 'https://courseapps.pepperdine.coursedev.keypathedu.com/dolly/public',
      lms: 'https://courses.pepperdine.edu',
      snippets: 'https://courseapps.mppl.onlinegrad.pepperdine.edu/lms/snippets/',
      uiKit: 'https://xd.adobe.com/view/8c4d731d-9b14-4145-b943-78f6db118fbf-d91e/',
      bitbucket: 'https://bitbucket.org/keypathcd/pepperdine/src/main/',
    },
    offboarded: true,
  },

  rockhurst: {
    displayName: 'Rockhurst University (RU)',
    links: {
      dolly: 'https://courseapps.onlinehealth.rockhurst.edu/dolly/public/',
      lms: 'https://rockhurst.instructure.com/',
      snippets: 'https://courseapps.onlinehealth.rockhurst.edu/snippets/',
      bitbucket: 'https://bitbucket.org/keypathcd/rockhurst/src/main/',
      fastFiles: 'https://courseapps.onlinehealth.rockhurst.edu/fastfiles/dist/#/',
    },
  },

  rmit: {
    displayName: 'Royal Melbourne Institute of Technology (RMIT)',
    links: {
      dolly: '',
      lms: 'https://rmit.instructure.com/',
      snippets: '',
      bitbucket: 'https://bitbucket.org/keypathcd/rmit/src/main/',
    },
  },

  sbu: {
    displayName: 'St. Bonaventure University (SBU)',
    links: {
      dolly: 'https://moodleresources.sbu.edu/dolly/public',
      lms: 'https://sbuonline.sbu.edu/login/index.php',
      snippets: 'https://sbuonline.sbu.edu/onlinecampus/internal/snippets/index.html#/',
      uiKit:
        'https://www.figma.com/file/M5nwko60yhGDsegHrNVNcy/SBU-UI-Kit-(AshP)?type=design&node-id=46%3A4&mode=design&t=B5KJ5z5I6zZpHxJW-1',
      bitbucket: 'https://bitbucket.org/keypathcd/sbu/src/main/',
      fastFiles: 'https://sbuonline.sbu.edu/onlinecampus/fastfiles/dist/#/',
    },
  },

  scu: {
    displayName: 'Southern Cross University (SCU)',
    links: {
      dolly: 'https://courseapps.online.scu.edu.au/dolly/public',
      lms: 'http://staging.keypathassets.com/moodle/mod/page/view.php?id=628',
      snippets: '',
      bitbucket: 'https://bitbucket.org/keypathcd/scu/src/master/',
      fastFiles: 'https://courseapps.online.scu.edu.au/fastfiles/dist/#/',
    },
  },

  sim: {
    displayName: 'Singapore Institute of Management (SIM)',
    links: {
      dolly: 'https://courseapps.postgradonline.simge.edu.sg/dolly/public',
      lms: 'https://onlinelearn.sim.edu.sg/login/canvas',
      snippets: 'https://courseapps.postgradonline.simge.edu.sg/snippets/',
      bitbucket: 'https://bitbucket.org/keypathcd/sim/src/main/',
      uiKit:
        'https://www.figma.com/file/y5WYQkKYOLoF3aPPyEG1mn/SIM-UI-Kit?type=design&node-id=0%3A1&mode=design&t=oFgkvwSvZkO4Rl4u-1',
      fastFiles: 'https://courseapps.postgradonline.simge.edu.sg/fastfiles/dist/#/',
    },
  },

  sjc: {
    displayName: "Saint Joseph's College of Maine (SJC)",
    links: {
      dolly: 'https://courseapps.studyonline.sjcme.edu/dolly/public',
      lms: 'https://sjcme.brightspace.com/d2l/login',
      snippets: 'https://courseapps.studyonline.sjcme.edu/snippets',
      uiKit: 'https://www.figma.com/file/3EN2R1andFoLVqkLmZLZQT/SJC-UI-Kit-(AshP)?t=X6zJg9HdLbHKcYm7-6',
      bitbucket: 'https://bitbucket.org/keypathcd/sjc/src/main/',
      fastFiles: 'https://courseapps.studyonline.sjcme.edu/fastfiles/dist/#/',
    },
  },

  spalding: {
    displayName: 'Spalding University (SPA)',
    links: {
      dolly: 'https://courseapps.online.spalding.edu/dolly/public',
      lms: 'https://spalding.instructure.com',
      snippets: 'https://courseapps.online.spalding.edu/snippets/',
      uiKit:
        'https://www.figma.com/file/7WJXI83QD6Vaob8tcq7IRu/Spalding-UI-Kit?type=design&node-id=0%3A1&mode=design&t=sPdq2DRP0uoxcC8T-1',
      bitbucket: 'https://bitbucket.org/keypathcd/spalding/src/main/',
      fastFiles: 'https://courseapps.online.spalding.edu/fastfiles/dist/#/',
    },
  },

  suffolk: {
    displayName: 'Suffolk University (SUF)',
    links: {
      dolly: 'https://courseapps.online.suffolk.edu/dev/dolly/public',
      lms: 'https://suffolk.instructure.com',
      snippets: 'https://courseapps.online.suffolk.edu/dev/Snippets',
      bitbucket: 'https://bitbucket.org/keypathcd/suffolk-canvas/src/main/',
    },
    offboarded: true,
  },

  sunway: {
    displayName: 'Sunway University (SU)',
    links: {
      dolly: 'https://courseapps.studyonline.sunway.edu.my/dolly/public',
      lms: 'https://elearn.sunway.edu.my',
      snippets: 'https://courseapps.studyonline.sunway.edu.my/snippets',
      bitbucket: 'https://bitbucket.org/keypathcd/sunway/src/main/',
      fastFiles: 'https://courseapps.studyonline.sunway.edu.my/fastfiles/dist/#/',
    },
  },

  twu: {
    displayName: "Texas Woman's University (TWU)",
    links: {
      dolly: 'https://dolly.courseapps.onlinenursing.twu.edu',
      lms: 'https://twu.instructure.com',
      snippets: 'https://courseapps.onlinenursing.twu.edu/snippet/index.html',
      uiKit: 'https://www.figma.com/file/19o5zRL5BUOmBV3PuAZcTJ/TWU-UI-Kit-(AshP)?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/twu/src/main/',
      fastFiles: 'https://courseapps.onlinenursing.twu.edu/fastfiles/dist/#/',
    },
  },

  canberra: {
    displayName: 'University of Canberra (UC)',
    links: {
      dolly: 'https://courseapps.studyonline.canberra.edu.au/dolly/public',
      lms: 'https://unicanberra.instructure.com/login/canvas',
      snippets: 'https://courseapps.studyonline.canberra.edu.au/snippets',
      uiKit: 'https://xd.adobe.com/view/fd7fba91-a2d9-4832-8c9d-d4805997faf6-a82c/',
      bitbucket: 'https://bitbucket.org/keypathcd/canberra/src/main/',
      fastFiles: 'https://courseapps.studyonline.canberra.edu.au/fastfiles/dist/#/',
    },
  },

  exeter: {
    displayName: 'University of Exeter (UOE)',
    noPhoneHome: true,
    links: {
      dolly: 'https://keypath.exeter.ac.uk/dolly/public',
      lms: 'https://vle.exeter.ac.uk/auth/saml2/login.php',
      snippets: 'https://s3.amazonaws.com/keypath-coursedev/snippets/Exeter/snippets.html',
      bitbucket: 'https://bitbucket.org/keypathcd/exeter/src/main/',
    },
    offboarded: true,
  },

  ufo: {
    displayName: 'University of Findlay (UFO)',
    links: {
      dolly: 'https://courseapps.pharmdonline.findlay.edu/dolly/public/',
      lms: 'https://canvas.findlay.edu/',
      snippets: 'https://courseapps.pharmdonline.findlay.edu/snippets/',
      uiKit: 'https://www.figma.com/file/Yd0wMCUnA26FIKQQCuvS5M/UFO-UI-Kit-(AshP)?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/ufo/src/main/',
      fastFiles: 'https://courseapps.pharmdonline.findlay.edu/fastfiles/dist/#/',
    },
  },

  unsw: {
    displayName: 'University of New South Wales (UNSW)',
    links: {
      dolly: 'https://courseapps.studyonline.unsw.edu.au/apps/dolly/public',
      lms: 'https://moodle.telt.unsw.edu.au/login/index.php',
      snippets: 'https://courseapps.studyonline.unsw.edu.au/unsw/unsw_snippet_2/index.html',
      uiKit: 'https://xd.adobe.com/view/ed0d748c-505b-4d9d-a657-fae949d4085e-b85e/',
      bitbucket: 'https://bitbucket.org/keypathcd/unsw/src/main/',
    },
  },

  walsh: {
    displayName: 'Walsh University (WU)',
    links: {
      dolly: 'https://courseapps.online.walsh.edu/dolly/public',
      lms: 'https://ecn.walsh.edu/',
      snippets:
        'https://ecn.walsh.edu/access/content/group/e66a8254-87e3-4a50-bb6f-46fcecd72f07/dev/snippet_library/index.html',
      uiKit: 'https://www.figma.com/file/QTF3UxDnIIjTB0vLVU2ubA/Walsh-UI-Kit-(AshP)?t=OGZfMWBIuDnaKtuc-6',
      bitbucket: 'https://bitbucket.org/keypathcd/walsh/src/main/',
      fastFiles: 'https://courseapps.online.walsh.edu/fastfiles/dist/#/',
    },
  },
  wilkes: {
    displayName: 'Wilkes University (WLK)',
    links: {
      dolly: 'https://courseapps.onlinenursingdegrees.wilkes.edu/dolly/public',
      lms: 'https://live.wilkes.edu/d2l/login',
      snippets: 'https://courseapps.onlinenursingdegrees.wilkes.edu/dev/theme/snippet_library',
      uiKit: 'https://xd.adobe.com/view/70542280-f5f3-40b1-afb2-02ba79fd600d-2507/',
      bitbucket: 'https://bitbucket.org/keypathcd/wilkes/src/main/',
    },
  },
};
