import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import Popper from 'vue3-popper';
import VueGtag from 'vue-gtag';

window.kpProductNavStore = store;
createApp(App)
  .use(VueGtag, {
    config: { id: 'G-807HNR6Y19' },
  })
  .use(store)
  .component('Popper', Popper)
  .mount('#kp-nav');
