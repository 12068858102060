<template>
  <div class="kpnav_sr-only">Close Menu</div>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.77 28.77">
    <polygon
      fill="currentColor"
      points="28.77 1.76 27.02 0 14.39 12.63 1.76 0 0 1.76 12.63 14.39 0 27.02 1.76 28.77 14.39 16.15 27.02 28.77 28.77 27.02 16.15 14.39 28.77 1.76"
    />
  </svg>
</template>
<style scoped>
svg {
  width: 60%;
  height: 60%;
}
</style>
