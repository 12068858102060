<template>
  <transition
    name="slide2"
    @after-enter="store.commit('animationEnded')"
    @before-leave="store.commit('animationStarted')"
  >
    <div id="kpnav_queue-box-container" v-if="queueShown">
      <Box class="kpnav_relative" title="WD Queue Availability">
        <InfoIcon id="kpnav_queue-info" :content="queueStatusLegend" :icon="questionMarkIcon"></InfoIcon>
        <QueueStatus />
      </Box>
    </div>
  </transition>

  <transition
    name="slide"
    @before-enter="store.commit('animationStarted')"
    @after-leave="store.commit('animationEnded')"
  >
    <div id="kpnav_menu" v-if="drawerOpen">
      <div id="kpnav_product-box-container">
        <Box title="Select Product">
          <ProductSelectionBank />
        </Box>
      </div>
      <div id="kpnav_partner-box-container">
        <Box class="kpnav_step-2-box" v-if="showStep2" title="Partners" :overFlowScroll="true">
          <PartnerSelectionBank :product="activeProduct.code" :key="activeProduct.code" />
        </Box>
      </div>
      <div class="kpnav_menu-footer">
        <img
          id="kpnav_logo"
          src="https://keypath-coursedev.s3.amazonaws.com/product_nav/images/KeypathLogo.svg"
          alt="Keypath Education Logo"
        />
        <button id="kpnav_close-menu-button" @click.prevent="toggleDrawer">
          <XIcon />
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Box from '../components/Box.vue';
import XIcon from '../components/icons/XIcon.vue';

import ProductSelectionBank from '../components/ProductSelectionBank.vue';
import PartnerSelectionBank from '../components/PartnerSelectionBank.vue';
import QueueStatus from '../components/QueueStatus.vue';
import InfoIcon from './QueueInfo.vue';

const store = useStore();

const showStep2 = computed(() => store.state.activeProduct.code !== null);
const drawerOpen = computed(() => store.state.drawerOpen);
const activeProduct = computed(() => store.state.activeProduct);
const queueShown = computed(() => store.state.queueShown);

const questionMarkIcon =
  '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.26 500"><g id="Edit"><path d="m168.18,398.75c-5.54-5.33-12.03-9.54-19.43-12.66-7.4-3.12-15.37-4.67-23.92-4.67s-16.87,1.55-24.27,4.67c-7.4,3.12-13.82,7.33-19.23,12.66-5.44,5.33-9.71,11.57-12.84,18.73-3.12,7.16-4.69,14.91-4.69,23.23s1.57,16.13,4.69,23.4c3.12,7.29,7.4,13.6,12.84,18.9,5.41,5.33,11.83,9.47,19.23,12.49,7.4,2.99,15.5,4.5,24.27,4.5s16.52-1.51,23.92-4.5c7.4-3.01,13.89-7.16,19.43-12.49,5.55-5.3,9.89-11.61,13.01-18.9,3.12-7.27,4.67-15.08,4.67-23.4s-1.55-16.07-4.67-23.23c-3.12-7.16-7.47-13.4-13.01-18.73Z"/><path d="m244.12,33.47c-12.27-10.74-27.11-19.01-44.55-24.8C182.11,2.9,162.5,0,140.78,0c-15.5,0-29.89,1.33-43.18,4-13.29,2.66-25.71,6.35-37.26,11.09-11.57,4.74-22.37,10.24-32.44,16.48-10.04,6.24-19.36,12.95-27.9,20.1l28.42,43.7c4.87,8.56,12.25,12.81,22.2,12.81,4.39,0,8.78-1.27,13.16-3.8,4.41-2.55,9.54-5.39,15.43-8.51,5.89-3.12,12.95-5.94,21.15-8.49,8.21-2.53,18.45-3.8,30.69-3.8,15.96,0,28.49,4.1,37.64,12.29,9.12,8.21,13.69,19.25,13.69,33.12,0,11.57-2.42,21.57-7.27,30.02-4.87,8.43-10.98,16.07-18.38,22.88-7.4,6.81-15.39,13.19-23.93,19.08-8.56,5.89-16.55,12.01-23.93,18.38-7.4,6.35-13.53,13.34-18.38,20.98-4.87,7.62-7.29,16.52-7.29,26.7,0,1.38.06,2.77.17,4.15.11,1.4.28,2.77.52,4.17l7.62,55.84h73.52l9.71-48.55c1.38-7.18,5.02-13.64,10.91-19.43,5.9-5.78,12.84-11.68,20.8-17.68,7.99-6,16.42-12.42,25.32-19.25,8.88-6.81,17.09-14.95,24.62-24.45,7.51-9.47,13.69-20.56,18.56-33.29,4.85-12.7,7.27-27.96,7.27-45.76s-3.3-35.32-9.89-50.47c-6.57-15.13-16-28.07-28.25-38.84Z"/></g></svg>';

function toggleDrawer() {
  store.commit('toggleDrawer');
}
</script>

<style lang="scss" scoped>
@import '../variables.scss';

#kpnav_app {
  //nesting needed for specificity since this will be deployed in same context of other apps

  #kpnav_menu {
    padding: 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 10px;

    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 400px;
    max-width: 100vw;

    background: white;

    transition: transform 0.5s;
  }

  //transitions
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(-100%);
  }

  .slide2-enter-active,
  .slide2-leave-active {
    transition: transform 0.5s;
  }

  .slide2-enter-from,
  .slide2-leave-to {
    transform: translateX(-800px) scale(0.7);
  }

  #kpnav_queue-box-container {
    background: white;
    width: 260px;
    position: absolute;
    left: 420px;
    top: 20px;
    padding: 20px;
    border-radius: 0.5rem;
    z-index: 2;
  }

  #kpnav_product-box-container {
    border-bottom: 2px solid $accent;
  }

  #kpnav_queue-box-container {
    padding-bottom: 15px;
  }

  #kpnav_partner-box-container {
    min-height: 0;
    height: 100%;
  }

  .kpnav_menu-footer {
    padding-top: 1em;
    padding-bottom: 1em;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    img {
      width: 120px;
    }
  }

  #kpnav_close-menu-button {
    background: $light-grey;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
      height: 50%;
    }

    &:hover {
      background: $secondary-keypath;
      color: white;
    }
  }

  ul {
    font-family: $font;
  }
}
</style>
