<template>
  <div class="kpnav_product-selection-bank">
    <label
      v-for="(product, code) in products"
      :key="code"
      class="kpnav_product-radio-label"
      :class="{ 'kpnav_selected-product-button': activeProductCode === code }"
    >
      <input
        type="radio"
        class="kpnav_product-radio kpnav_sr-only"
        name="product-selection"
        :value="code"
        v-model="activeProductCode"
      />
      <a
        v-if="product.hasOwnProperty('staticLink')"
        :href="product.staticLink"
        target="_blank"
        class="kpnav_product-single-link"
      >
        <div class="kpnav_product-psuedo-radio-icon">
          <ProductIcon :code="code" />
        </div>
        <div>{{ product.displayName }}</div>
      </a>
      <div v-else class="kpnav_product-psuedo-radio">
        <div class="kpnav_product-psuedo-radio-icon">
          <ProductIcon :code="code" />
        </div>
        <div>{{ product.displayName }}</div>
      </div>
    </label>
  </div>
</template>

<script setup>
import { products } from '../data/data.js';
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import ProductIcon from './icons/ProductIcon.vue';

const store = useStore();

const activeProductCode = computed({
  get() {
    return store.state.activeProduct.code;
  },
  set(newValue) {
    store.commit('setActiveProduct', newValue);
  },
});
</script>

<style lang="scss" scoped>
@import '../variables.scss';

#kpnav_app {
  //nesting needed for specificity since this will be deployed in same context of other apps

  label {
    color: $dark !important;
    cursor: pointer;
    background-color: white;
    box-shadow: none;
  }

  .kpnav_product-selection-bank {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 0.5em;
    padding-bottom: 1em;
  }

  .kpnav_product-psuedo-radio,
  .kpnav_product-single-link {
    display: flex;
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.8em;
    gap: 1em;
    font-family: $font;
    font-size: 16px;
    text-decoration: none;

    &:hover {
      box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.2);
    }
  }

  .kpnav_product-radio:focus-visible + .kpnav_product-psuedo-radio {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.7);
  }

  .kpnav_product-psuedo-radio-icon {
    width: 1.2em;
    text-align: center;
    color: $secondary-keypath;
  }

  .kpnav_product-radio:checked + .kpnav_product-psuedo-radio {
    color: white;
    background: $secondary-keypath;

    .kpnav_product-psuedo-radio-icon {
      color: white;
    }
  }

  .kpnav_product-single-link {
    color: #333333;

    &:visited {
      color: #302e2e;
    }
  }
}
</style>
