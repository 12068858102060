<template>
  <div class="kpnav_sr-only">Open Keypath Product Nav</div>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.736" height="22.736" viewBox="0 0 22.736 22.736">
    <g id="ic_Apps" data-name="ic Apps" transform="translate(68.755 -8.625)">
      <path
        id="path"
        d="M-68.755,14.309h5.684V8.625h-5.684Zm8.526,17.052h5.684V25.677h-5.684Zm-8.526,0h5.684V25.677h-5.684Zm0-8.526h5.684V17.151h-5.684Zm8.526,0h5.684V17.151h-5.684ZM-51.7,8.625v5.684h5.684V8.625Zm-8.526,5.684h5.684V8.625h-5.684Zm8.526,8.526h5.684V17.151H-51.7Zm0,8.526h5.684V25.677H-51.7Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
